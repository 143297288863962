import { renderDisplayAd } from './utils';
// @ts-ignore
const { wafer } = window;
const WAFER_SUCCESS_EVENT = 'fetch:success';

/**
 * When an async module is loaded with wafer fetch, on successful completion of
 * wafer fetch of recirc modules like relatedContent or relatedStories or moreStories module,
 * render LDRB2 before the footer - We are reusing LDRB2 position to show this leader board ad at the bottom
 */
function _waferFetchSuccessHandler() {
    wafer.on(WAFER_SUCCESS_EVENT, function (event) {
        const waferFetchUrl = event?.meta?.url;

        let isRelatedStoriesModule = waferFetchUrl?.includes(
            'module=relatedStories'
        );
        let isRelatedContentModule = waferFetchUrl?.includes(
            'module=relatedContent'
        );
        let isMoreStoriesModule = waferFetchUrl?.includes('module=moreStories');

        if (
            isRelatedStoriesModule ||
            isRelatedContentModule ||
            isMoreStoriesModule
        ) {
            const ldrbMarkup = document.getElementById('sda-LDRB2-9');

            const hasLDRBRendered = ldrbMarkup?.firstChild?.hasChildNodes();

            if (ldrbMarkup && !hasLDRBRendered) {
                renderDisplayAd(event, ['LDRB2-9'], 'CLIENTSIDELDRB');
            }
        }
    });
}

/**
 * Perform the following actions when wafer is ready
 */
function onLoad() {
    // wafer fetch success handler
    _waferFetchSuccessHandler();
}

if (wafer) {
    wafer.ready(() => {
        onLoad();
    }, window);
}
